
import { computed, defineComponent } from '@nuxtjs/composition-api';

import pascalToKebab from '~/helpers/pascalToKebab';
import kebabToPascal from '~/helpers/kebabToPascal';
import ProductDetails from '~/components/sections/productSection/ProductDetails.vue';
import ProductSeriesHeader from '~/components/sections/productSection/ProductSeriesHeader.vue';
import ProductSeries from '~/components/sections/productSection/ProductSeries.vue';
import ProductWarrantyBanner from '~/components/sections/productSection/ProductWarrantyBanner.vue';
import ProductContentBanner from '~/components/sections/productSection/ProductContentBanner.vue';
import ProductFeatures from '~/components/sections/productSection/ProductFeatures.vue';
import ProductFeaturesBanner from '~/components/sections/productSection/ProductFeaturesBanner.vue';
import ProductCertificates from '~/components/sections/productSection/ProductCertificates.vue';
import LatestNews from '~/components/sections/newsSection/LatestNews.vue';
import BannersSlider from '~/components/sections/sliderSection/BannersSlider.vue';
import SolutionsCarousel from '~/components/sections/sliderSection/SolutionsCarousel.vue';
import CategoriesSection from '~/components/sections/categorySection/CategoriesSection.vue';
import CategoryBanner from '~/components/sections/categorySection/CategoryBanner.vue';
import CategoryBannerV2 from '~/components/sections/categorySection/CategoryBannerV2.vue';
import CategoryInfoBlocks from '~/components/sections/categorySection/CategoryInfoBlocks.vue';
import CategoryFeatures from '~/components/sections/categorySection/CategoryFeatures.vue';
import CategoryAccessories from '~/components/sections/categorySection/CategoryAccessories.vue';
import CategoryApplications from '~/components/sections/categorySection/CategoryApplications.vue';
import CategoryFaq from '~/components/sections/categorySection/CategoryFaq.vue';
import CategoryProductSeries from '~/components/sections/categorySection/CategoryProductSeries.vue';
import CategoryRecommended from '~/components/sections/categorySection/CategoryRecommended.vue';
import CategoryRecommendedProducts from '~/components/sections/categorySection/CategoryRecommendedProducts.vue';
import SoftwareCategoryFirstScreen from '~/components/sections/categorySection/SoftwareCategoryFirstScreen.vue';
import SoftwareCategoryAwards from '~/components/sections/categorySection/SoftwareCategoryAwards.vue';
import SoftwareCategoryInfoblocks from '~/components/sections/categorySection/SoftwareCategoryInfoblocks.vue';
import SoftwareCategoryReviewsSlider from '~/components/sections/categorySection/SoftwareCategoryReviewsSlider.vue';
import SoftwareCategoryTryDemo from '~/components/sections/categorySection/SoftwareCategoryTryDemo.vue';
import SoftwareCategoryTryDemoFormModal from '~/components/sections/categorySection/SoftwareCategoryTryDemoFormModal.vue';
import SoftwareCategoryChoosePlan from '~/components/sections/categorySection/SoftwareCategoryChoosePlan.vue';
import SoftwareCategoryUserHelp from '~/components/sections/categorySection/SoftwareCategoryUserHelp.vue';
import CatalogCategories from '~/components/sections/categorySection/CatalogCategories.vue';
import ConsultationForm from '~/components/sections/formSection/ConsultationForm.vue';
import ServiceCentersSection from '~/components/sections/serviceCentersSection/ServiceCentersSection.vue';
import CtaSectionWithBackground from '~/components/sections/ctaSection/CtaSectionWithBackground.vue';
import CtaSectionBanner from '~/components/sections/ctaSection/CtaSectionBanner.vue';
import ProductBenefits from '~/components/sections/productSection/ProductBenefits.vue';
import ProductFootnote from '~/components/sections/productSection/ProductFootnote.vue';
import ProductVideo from '~/components/sections/productSection/ProductVideo.vue';
import PartnersSection from '~/components/sections/categorySection/PartnersSection.vue';
import ServiceForm from '~/components/sections/formSection/ServiceForm.vue';

import {
  FeatureSectionPoints,
  FeatureSectionSimpleListVariation,
  HeroSection,
  ContentSectionFootnote,
  FeatureSectionBannerVariation,
  FeatureSectionBenefits,
  FeatureSectionPartners,
  FeatureSectionSimpleListBackground,
  FeatureSectionVideoTabs,
  HeroSectionBanner,
  BlogSectionSimple,
  CardSectionApps,
  CardSectionAwards,
  CardSectionCatalog,
  CardSectionFlags,
  CardSectionGrid,
  CardSectionShops,
  CardSectionSimple,
  DocumentSectionSimple,
  FaqSectionSimple,
  LogoCloudSectionSimple,
  LogoCloudSectionWithFeatures,
  TeamSectionSimple,
  TestimonialSectionSlider,
  VideoSectionSimple,
  FeatureSectionArticles,
  FeatureSectionCarousel,
  FeatureSectionColumns,
  FeatureSectionInfoBlocks,
  FeatureSectionRecommended,
  FeatureSectionSimple,
  FeatureSectionSimpleList,
  FeatureSectionTextImage,
  HeaderSectionBackground,
  HeaderSectionImage,
  HeaderSectionSimple,
  HeaderSectionWithBanner,
  ContentSectionSeo,
  VideoSectionTabs,
  CardSectionCategories,
  FeatureSectionBanner,
  FeatureSectionBullets,
  FeatureSectionCarouselButton,
  FeatureSectionSmallBanners,
  CardSectionSmallBlocks,
  BlogSectionTextImage,
  ContentSectionTips,
  sectionsWithDataProps,
} from '@webplatform/asbis-ui/dist/sections';

import CatalogHead from '~/domains/catalog/components/CatalogHead';
import HtmlContentBlock from '~/components/HtmlContentBlock';
import BookMeetingPopup from '~/domains/book-meeting/components/BookMeetingPopup';

const NO_COMPONENT_TYPE = [
  'product-series-additional-information',
  'software-documents',
];

const ASBIS_NO_DATA_BINDING_COMPONENTS = [
  'header-section-simple',
  'header-section-with-banner',
  'header-section-image',
  'service-centers-section',
  'faq-section-simple',
  'blog-section-simple',
  'card-section-apps',
  'card-section-awards',
  'card-section-catalog',
  'card-section-flags',
  'card-section-grid',
  'card-section-shops',
  'card-section-simple',
  'document-section-simple',
  'logo-cloud-section-simple',
  'logo-cloud-section-with-features',
  'team-section-simple',
  'testimonial-section-slider',
  'video-section-simple',
  'feature-section-articles',
  'feature-section-carousel',
  'feature-section-carousel-button',
  'feature-section-small-banners',
  'feature-section-columns',
  'feature-section-info-blocks',
  'feature-section-recommended',
  'feature-section-simple',
  'feature-section-simple-list',
  'feature-section-text-image',
  'header-section-background',
  'feature-section-simple-list-variation',
  'feature-section-points',
  'feature-section-simple-list-background',
  'feature-section-video-tabs',
  'hero-section',
  'content-section-tips',
  'card-section-categories',
];

const components = {
  CatalogCategories,
  CatalogHead,
  ProductDetails,
  ProductSeriesHeader,
  ProductSeries,
  ProductFeaturesBanner,
  ProductCertificates,
  ProductWarrantyBanner,
  ProductContentBanner,
  ProductFeatures,
  BannersSlider,
  CategoriesSection,
  SolutionsCarousel,
  LatestNews,
  CategoryBanner,
  CategoryBannerV2,
  CategoryInfoBlocks,
  CategoryFeatures,
  CategoryProductSeries,
  CategoryApplications,
  CategoryAccessories,
  CategoryRecommended,
  CategoryFaq,
  ConsultationForm,
  SoftwareCategoryFirstScreen,
  SoftwareCategoryAwards,
  SoftwareCategoryInfoblocks,
  SoftwareCategoryReviewsSlider,
  SoftwareCategoryTryDemo,
  SoftwareCategoryTryDemoFormModal,
  SoftwareCategoryChoosePlan,
  SoftwareCategoryUserHelp,
  BookMeetingPopup,
  HtmlContentBlock,
  CategoryRecommendedProducts,
  HeaderSectionSimple,
  HeaderSectionWithBanner,
  HeaderSectionImage,
  ServiceCentersSection,
  CtaSectionWithBackground,
  CtaSectionBanner,
  ProductBenefits,
  ProductFootnote,
  ProductVideo,
  PartnersSection,
  BlogSectionSimple,
  CardSectionApps,
  CardSectionAwards,
  CardSectionCatalog,
  CardSectionFlags,
  CardSectionGrid,
  CardSectionShops,
  CardSectionSimple,
  DocumentSectionSimple,
  FaqSectionSimple,
  LogoCloudSectionSimple,
  LogoCloudSectionWithFeatures,
  TeamSectionSimple,
  TestimonialSectionSlider,
  VideoSectionSimple,
  FeatureSectionArticles,
  FeatureSectionCarousel,
  FeatureSectionColumns,
  FeatureSectionInfoBlocks,
  FeatureSectionRecommended,
  FeatureSectionSimple,
  FeatureSectionSimpleList,
  FeatureSectionTextImage,
  HeaderSectionBackground,
  ContentSectionSeo,
  VideoSectionTabs,
  CardSectionCategories,
  FeatureSectionBanner,
  FeatureSectionBullets,
  FeatureSectionCarouselButton,
  FeatureSectionSmallBanners,
  CardSectionSmallBlocks,
  BlogSectionTextImage,
  ContentSectionTips,
  FeatureSectionPoints,
  FeatureSectionSimpleListVariation,
  HeroSection,
  ContentSectionFootnote,
  FeatureSectionBannerVariation,
  FeatureSectionBenefits,
  FeatureSectionPartners,
  FeatureSectionSimpleListBackground,
  FeatureSectionVideoTabs,
  HeroSectionBanner,
  ServiceForm,
};

export default defineComponent({
  components,
  props: {
    blocks: {
      type: Array,
      default: () => [],
    },
    product: {
      type: Object,
      default: (value) => value || null,
    },
  },
  setup(props) {
    // eslint-disable-next-line camelcase
    const prepareBlock = ({ type, content, data, content_type }) => {
      if (type === 'textarea')
        return { type: 'html-content-block', data: content };
      // eslint-disable-next-line camelcase
      if (content_type && content_type === 'asbis-ui' && !content) return data;

      return content ?? { type, data };
    };

    const pageComponents = computed(() =>
      props.blocks
        .map(prepareBlock)
        ?.filter(
          (item) =>
            !NO_COMPONENT_TYPE.includes(item.type) &&
            components[pascalToKebab(item.type)],
        ),
    );

    const isDataBindingComponent = (componentType) => {
      return sectionsWithDataProps.includes(kebabToPascal(componentType));
    };
    const isNoDataBindingComponents = (componentType) =>
      ASBIS_NO_DATA_BINDING_COMPONENTS.includes(componentType);

    const isAsbisComponent = (componentType) =>
      isDataBindingComponent(componentType) ||
      isNoDataBindingComponents(componentType);
    return {
      pageComponents,
      isDataBindingComponent,
      isNoDataBindingComponents,
      isAsbisComponent,
    };
  },
});
