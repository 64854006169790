import { ref, useContext } from '@nuxtjs/composition-api';

const useDPD = () => {
  const { i18n, $axios } = useContext();
  const dpdApi = $axios.create({
    baseURL: 'https://pickup.dpd.cz/api',
  });

  const dpdCountries = ref<any[]>([]);
  const isDpdCountriesLoading = ref(false);

  const dpdCities = ref<any[]>([]);
  const isDpdCitiesLoading = ref(false);

  const dpdAddresses = ref<any[]>([]);
  const isDpdAddressesLoading = ref(false);

  const getDpdCountries = async () => {
    isDpdCountriesLoading.value = true;
    const { data } = await dpdApi.get('/get-countries', {
      params: {
        lang: i18n.locale,
      },
    });
    isDpdCountriesLoading.value = false;
    dpdCountries.value = data.data.items;

    return data;
  };

  const getDpdCities = async (country: number) => {
    isDpdCitiesLoading.value = true;
    const { data } = await dpdApi.get('/get-all', {
      params: {
        lang: i18n.locale,
        country,
      },
    });
    isDpdCitiesLoading.value = false;
    dpdCities.value = data.data.items;

    return data;
  };
  const getDpdAdressesByCity = async (city: string) => {
    isDpdAddressesLoading.value = true;
    const { data } = await dpdApi.get('/get-parcel-shops-by-address', {
      params: {
        lang: i18n.locale,
        limit: 20, // Max - 100, но слишком большой массив получается
        address: city,
      },
    });
    isDpdAddressesLoading.value = false;
    dpdAddresses.value = data.code !== 1001 ? data.data.items : [];

    return data;
  };
  return {
    dpdCountries,
    dpdCities,
    dpdAddresses,
    getDpdCountries,
    getDpdCities,
    getDpdAdressesByCity,
    isDpdCountriesLoading,
    isDpdCitiesLoading,
    isDpdAddressesLoading,
  };
};

export default useDPD;
